import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '@mobileia/authentication';

@Component({
  selector: 'mia-layout-elite-login-page',
  template: `<section id="wrapper">
    <div class="login-register" [ngStyle]="{'background-image': designImageBackground !== '' ? 'url('+ designImageBackground +')' : '' }">
        <div *ngIf="designImageLogo != ''" class="login-box text-center p-b-10">
            <img [src]="designImageLogo" style="max-width: 100%" />
        </div>
        <div class="login-box card">
            <div class="card-body">
                <form class="form-horizontal form-material" [formGroup]="loginForm" (ngSubmit)="submit()">
                    <h3 class="box-title m-b-20">{{ designTitle }}</h3>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <input class="form-control" type="text" required="" placeholder="Email" formControlName="email" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input class="form-control" type="password" required="" placeholder="ContraseÃ±a" formControlName="password">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck1">
                                <label class="custom-control-label" for="customCheck1">Recuerdame</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="loginMessageError != ''" class="alert alert-danger">
                        <span>{{ loginMessageError }}</span>
                    </div>
                    <div class="form-group text-center">
                        <div class="col-xs-12 p-b-20">
                            <button class="btn btn-block btn-lg {{ designBtnColorClass }} btn-rounded" [disabled]="!loginForm.valid" type="submit">Iniciar sesiÃ³n</button>
                        </div>
                    </div>
                    <!--<div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">
                            <div class="social">
                                <a href="javascript:void(0)" class="btn  btn-facebook" data-toggle="tooltip" title="Login with Facebook"> <i aria-hidden="true" class="fa fa-facebook"></i> </a>
                                <a href="javascript:void(0)" class="btn btn-googleplus" data-toggle="tooltip" title="Login with Google"> <i aria-hidden="true" class="fa fa-google-plus"></i> </a>
                            </div>
                        </div>
                    </div>-->
                    <!--<div class="form-group m-b-0">
                        <div class="col-sm-12 text-center">
                            Don't have an account? <a href="pages-register.html" class="text-info m-l-5"><b>Sign Up</b></a>
                        </div>
                    </div>-->
                    <div class="form-group m-b-0">
                        <div class="col-sm-12 text-center">
                            <a href="javascript:void(0)" id="to-recover" class="text-dark pull-right"><i class="fa fa-lock m-r-5"></i> Olvidaste tu contraseÃ±a?</a>
                        </div>
                    </div>
                </form>
                <form class="form-horizontal" id="recoverform" action="index.html">
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <h3>Recover Password</h3>
                            <p class="text-muted">Enter your Email and instructions will be sent to you! </p>
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <input class="form-control" type="text" required="" placeholder="Email"> </div>
                    </div>
                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>`,
  styles: [`@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);.login-register{background-size:cover;background-repeat:no-repeat;background-position:center center;height:100%;width:100%;padding:10% 0;position:fixed}.login-box{width:400px;margin:0 auto}.login-box .footer{width:100%;left:0;right:0}.login-box .social{display:block;margin-bottom:30px}#recoverform{display:none}.login-sidebar{padding:0;margin-top:0}.login-sidebar .login-box{right:0;position:absolute;height:100%}`]
})
export class LoginPageComponent implements OnInit {

  /**
   * Almacena la ruta cuando se loguea correctamente
   */
  private routeSuccess: String;
  /**
   * Determinar si en el ingreso debe validar el Rol del usuario.
   */
  private verifyRole = false;
  /**
   * Rol que tiene permisos para ingresar
   */
  private withRole = 0;

  loginForm: FormGroup;
  loginMessageError = '';

  /** Variables para personalizar el diseÃ±o */
  designImageBackground = '';
  designImageLogo = '';
  designTitle = 'Ingresar';
  designBtnColorClass = 'btn-info';

  constructor(private authService: AuthenticationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router) {
      this.buildForm();
     }

  ngOnInit() {
    // Guardar parametros enviados
    this.route.data.subscribe(params => {
      this.routeSuccess = params.success_route;
      if (params.verify_role) {
        this.verifyRole = params.verify_role;
        this.withRole = params.with_role;
      }
      // Procesar variables del diseÃ±o
      this.processCustomDesign(params);
    });

    this.route.queryParams.subscribe(params => {
      const redirect = params.redirect;
      if (redirect !== '/login' && redirect !== '/' && redirect !== '' && redirect !== null 
      && redirect !== undefined && redirect !== '%2F' && redirect !== '/login;redirect=%2F') {
        this.routeSuccess = redirect;
      }

      this.observableLogged();
    });

  }

  observableLogged() {
    this.authService.isLoggedBehavior().subscribe(logged => {
      if (logged) {
        this.router.navigateByUrl('/' + this.routeSuccess + '');
      }
    });
  }

  buildForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email]) ],
      password: ['', Validators.compose([Validators.required, Validators.minLength(3)]) ],
    });
  }

  submit() {
    const email = this.loginForm.get('email').value;
    const password = this.loginForm.get('password').value;
    this.requestLogin(email, password);
  }

  requestLogin(email: string, password: string) {
    // Limpiar mensaje de error
    this.loginMessageError = '';
    this.authService.signInWithEmailAndPassword(email, password, data => {
      if (data.success) {

        if (this.isValidRole(data.response.role)) {
          this.router.navigateByUrl('/' + this.routeSuccess);
        } else {
          this.loginMessageError = 'Usted no tiene permisos para registrarse';
          this.authService.signOut();
        }

      } else {
        this.loginMessageError = data.error.message;
      }
    });
  }
  /**
   * Funcion que determinar si el usuario para ingresar necesita un rol especial
   */
  isValidRole(userRole: number): Boolean {
    // Si no necesita verificar rol
    if (!this.verifyRole) {
      return true;
    }
    // Verificar si es el mismo rol
    if (this.withRole == userRole) {
      return true;
    }
    return false;
  }

  processCustomDesign(params) {
    if (params.image_background) {
      this.designImageBackground = params.image_background;
    }
    if (params.image_logo) {
      this.designImageLogo = params.image_logo;
    }
    if (params.title_box) {
      this.designTitle = params.title_box;
    }
    if (params.button_color) {
      this.designBtnColorClass = params.button_color;
    }
  }
}
